<template>
  <b-modal :active.sync="showModalPolitica" :can-cancel="false" >
    <div class="modals-bosy">
      <iconInfo
        :fill="design.colorModal"
        width="max-content"
      ></iconInfo>
      <span
        class="f18 source-semi-bold text-center"
      >{{ $t('checkoutView.components.text19') }}</span>
      <br>
      <p class="f14 source-sans">
        {{ $t('checkoutView.components.text20') }} <strong>{{ $t('checkoutView.components.text21') }}</strong> &nbsp;{{ $t('checkoutView.components.text22') }} &nbsp;<b> {{ cancelUpdateTime !== null ? secHour(cancelUpdateTime) : secHour(params.updateTime) }} </b> {{ $t('checkoutView.components.text23') }}
      </p>
      <br>
      <div class="col-12 text-center">
          <button @click="close" :style="'padding: 5px 40px; background-color:black;border-color:black;color:#fff'">
            {{ $t('checkoutView.components.text24') }}
          </button>
        </div>
    </div>
  </b-modal>
</template>
<script>
import iconInfo from '@/components/Icons/iconInfo';
import { mapGetters } from 'vuex';
import whiteLabelTypes from '@/store/types/whiteLabel';

export default {
  computed: {
    ...mapGetters({
      showModalPolitica: whiteLabelTypes.getters.showModalPolitica,
      cancelUpdateTime: whiteLabelTypes.getters.cancelUpdateTime
    })
  },
  components: { iconInfo },
  methods: {
    secHour (x) {
      var d = this.$moment.duration(x, 'milliseconds');
      var hours = d.asHours();
      var mins = d.asMinutes() - hours * 60;
      mins = mins <= 9 ? '0' + mins : mins;
      hours = hours <= 9 ? '0' + hours : hours;
      return hours + ':' + mins;
    },
    close () {
      this.$store.commit(whiteLabelTypes.mutations.setShowModalPolitica, false);
    }
  }
};
</script>
<style scoped>
  .btn:hover {
    background-color: #fff !important;
    color: #000 !important;
  }
</style>
